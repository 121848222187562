/* eslint-disable no-undef, react/prop-types */
import React from 'react';
import { css } from 'react-emotion';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import { Helmet } from 'react-helmet';
import { Flex, PaperBox } from '../components/Layout';
import PageWrapper from '../components/PageWrapper';
import colors from '../utils/colors';

import gooseberryPointer from '../assets/images/gooseberry-pointer.png';
import boxClosed from '../assets/images/box-closed.png';


const imgStyle = css`
  border-radius: 5px;
`;

const clickStyle = css`
  cursor: pointer;
`;

const guardianStyle = css`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 200px;
  z-index: -1;
`;

const msInDay = 24*60*60*1000;

class Intro extends React.Component {
  state = {
    introIndex: 0,
    days: 99,
  };

  timer = null;
  daysLeft = () => {
    const now = Date.now();
    const days = Math.ceil((new Date(2021,12-1,19) - now) / msInDay);

    this.setState({days});

    if (this.timer) clearInterval(this.timer);
    this.timer = setTimeout(() => {this.daysLeft()}, msInDay - (now % msInDay) + 1000);
  };

  componentDidMount() {
    this.daysLeft();
  };

  nextIntro = (e) => {
    this.setState({introIndex: (this.state.introIndex+1) % 2});
    e && e.stopPropagation && e.stopPropagation();
    return false;
  };

  render() {
    if( this.state.days < 0 ) location.href = "/whoarewe";
    return (
      <Flex justifyContent="center" align-items="center">
        <PaperBox
          width={[1, '80%', '60%']}
          m={['3.5rem auto', '5.5rem auto', '5.5rem auto']}
          px={[3, 3, 5]}
          py={[3, 3, 3]}
          textAlign="center"
          onClick={this.nextIntro}
        >
          { this.state.introIndex == 0 ?
            <>
              <h1>BEACONS</h1>
              <p>The Sea Gooseberries are coming...</p>
              <p>They want to share their magic, but they need your help!</p>
              <p><strong>Join the Quest to reunite the Sea Gooseberries and hear their songs.</strong></p>
              <p><strong>It's free and it's for everyone!</strong></p>
              <p>more...</p>
              <p>
                <img
                  src={gooseberryPointer}
                  alt="gooseberry pointer"
                  width="200"
                />
              </p>
            </>

          :
            <>
              <h1>Welcome to the Quest</h1>

              <p>Find the lost Sea Gooseberry song fragments around Folkestone, Hythe &amp; Romney Marsh.</p>
              <p>Register the ones you find via the <a href="/find">FOUND</a> page.</p>
              <p>Find enough, and a Sea Gooseberry might decide to visit you.</p>
              <p>When the Quest is complete, we will reunite the Sea Gooseberries at <a href="/gathering">The Gathering</a>, a big event for the whole community to come together on Sunday 19th December.</p>
              <p>We'll hear the song of the Sea Gooseberries and enjoy fire and feasting on the beach.</p>
              <p>Ready to begin? <a href="/map"><strong>Go to the MAP</strong></a></p>
              <p>
                <img
                  src={boxClosed}
                  alt="closed treasure box"
                  width="200"
                />
              </p>
              <p>The Quest ends
                { this.state.days == 0 ?  " today!" :
                  this.state.days == 1 ? " tomorrow!" :
                  <> in {this.state.days} days. Don’t leave it too late!</>
                }
              </p>
            </>
          }
        </PaperBox>
      </Flex>
    );
  }
};

export default ({ data }) => {
  const myData = data.allContentJson.edges[0].node.index;
  const mapData = data.map.childImageSharp;

  return (
    <PageWrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home - Beacons</title>
      </Helmet>

      <BackgroundImage
        Tag="section"
        className={imgStyle}
        fluid={mapData.fluid}
        backgroundColor={`#FFFFFF`}
        padding="20px"
      >
        <Intro></Intro>
      </BackgroundImage>
    </PageWrapper>
  );
};

export const query = graphql`
query {
  map: file(relativePath: { eq: "map.jpeg" }) {
    childImageSharp {
      fluid(maxWidth: 1500) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  allContentJson {
		edges {
			node {
				index {
					title
					aboutText
				}
      }
    }
  }
}
`;

// allContentJson - will query and array where there are multiple items. Any query not beginning with 'all' with query a single object
